<template>
  <div class="home">
      <h2>We are currently performing maintenance on the site! We will relaunch soon. Until then, you can learn more about Amherst Uprising on the amherst.edu webpage
          <a href="https://www.amherst.edu/library/find/researchguides/amherstuprising">by clicking here</a></h2>
      <img src="https://awikhigan.irlh.org/gen/wl/?id=J5jP790JRsGGin1ZL97P3M01t28Fh8aR" alt="Amherst Uprising logo">
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>

<style lang="scss">
    h2 {
        width: 800px;
        margin: 0 auto;
        text-align: center;
        padding-top: 15%;
    }
    
    img {
        width: 400px;
        padding-top: 100px;
    }
</style>
